<template>
  <div>
    <Sidebar />
    <v-card height="430" style="padding: 0px;margin: 0px" flat>
      <v-col class="p-0 position" style="padding: 0px">
        <v-img height="200" style="opacity: 0.7;" :src="onlineShop"> </v-img>
        <p class="text_page">{{ $t("message.shopping-guide") }}</p>
      </v-col>
    </v-card>
    <div class="mt-7">
      <v-card class="d-flex justify-center" flat>
        <v-btn @click.prevent="$router.push('/')" color="#ff0090" depressed class="white--text">
          {{ $t("message.go-shopping") }}
        </v-btn>
      </v-card>
      <CategoryFooter class="mb-0 p-0" />
    </div>
  </div>
</template>

<script>
import Sidebar from "../../components/Sidebar.vue";
import CategoryFooter from "../../components/CategoryFooter.vue";
export default {
  name: "ShoppingGuide",
  components: {
    Sidebar,
    CategoryFooter,
  },
  data() {
    return {
      onlineShop: require("../../assets/onlineshop.jpg"),
    };
  },
};
</script>

<style>
.text_page {
  position: absolute;
  left: 25%;
  top: 50%;
  font-weight: 1000;
  font-size: 25px;
  opacity: 10;
}
.position {
  position: relative;
}
</style>
